import React from 'react'
import { Helmet } from 'react-helmet'
import Head from '../header'
import Footer from '../footer'
import "../../../scss/index.scss"

const Layout = ({ children }) => (
  <>
    <Helmet>
      <script src="/prism.js" type="text/javascript" />
    </Helmet>
    <Head />
    <div className="container">
      <div className="content">
        {children}
      </div>
    </div>
    <Footer />
  </>
)

export default Layout
