import { useEffect, useState } from 'react'
import { isBrowser } from '../util'
/**
 * Theming.
 *
 * Supports the preferred color scheme of the operation system as well as
 * the theme choice of the user.
 *
 */

// Detect the color scheme the operating system prefers.
const chosenThemeIsDark = (theme) => theme == "dark"
const chosenThemeIsLight = (theme) => theme == "light"

const detectOSColorTheme = (theme) => {
  if (chosenThemeIsDark(theme)) {
    document.documentElement.setAttribute("data-theme", "dark")
  } else if (chosenThemeIsLight) {
    document.documentElement.setAttribute("data-theme", "light")
  } else if (isBrowser && window.matchMedia("(prefers-color-scheme: dark)").matches) {
    document.documentElement.setAttribute("data-theme", "dark")
  } else {
    document.documentElement.setAttribute("data-theme", "light")
  }
}

export const useTheme = () => {
  const [theme, setTheme] = useState(isBrowser && window.localStorage.getItem("theme"))
  useEffect(() => {
    if (isBrowser) {
      localStorage.setItem("theme", theme)
      detectOSColorTheme(theme)
      const themeChangeEvent = (e) => e.matches && detectOSColorTheme(theme)
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .addEventListener("change", themeChangeEvent)
      window
        .matchMedia("(prefers-color-scheme: light)")
        .addEventListener("change", themeChangeEvent)

      return () => {
        window
          .matchMedia("(prefers-color-scheme: dark)")
          .removeEventListener("change", themeChangeEvent)
        window
          .matchMedia("(prefers-color-scheme: light)")
          .removeEventListener("change", themeChangeEvent)
      }
    }
  }, [theme])

  const switchTheme = (e) => {
    if (chosenThemeIsDark(theme)) {
      setTheme("light")
    } else {
      setTheme("dark")
    }
  }

  return [theme, switchTheme]
}