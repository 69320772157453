import React from 'react'
import  Link from '../link'

const Footer = () => (
  <footer className="footer">
    <div className="footer__inner">
      <div className="footer__content">
        <span>&copy; {new Date().getFullYear()}</span>
        <span><Link to="https://github.com/rhazdon/hugo-theme-hello-friend-ng">Base Theme</Link></span>
        <span>Powered by <Link tohref="https://gatsby.com/">Gatsby</Link></span>
      </div>
    </div>
  </footer>
)
export default Footer
