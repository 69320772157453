import React from 'react';
import { graphql } from 'gatsby';
import Card from '../card'

const ProjectSnippet = ({ data: { title, description, date, tags, projectLink, image, githubLink } }) => (
  <Card
    title={title}
    link={projectLink}
    githubLink={githubLink}
    image={image}
    date={date}
    tags={tags}
    description={description}

  />
)

// For images we have 4:3 Aspect Ratio
export const postFrontMatter = graphql`fragment PostFrontMatter on MdxFrontmatter {
  title
  description
  date(formatString: "M D, YYYY")
  tags
  projectLink
  githubLink
  image {
    childImageSharp {
      gatsbyImageData(width: 800, layout: CONSTRAINED)
    }
  }
}
`

export default ProjectSnippet
