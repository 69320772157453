import React from 'react'
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import Link from '../link'
import './styles.scss'

const Card = ({ title, link, image, tags, description, githubLink }) => {
  const img = getImage(image)
  return (
    <div className="card__container">
      <div className="card__header">
        <div className="card__header__image_container">
          <GatsbyImage
            image={img}
            alt={`${title} icon`}
            className="card__image"
          />
        </div>
      </div>
      <div className="card__content">
        <h2>{title}</h2>
        <p>{description}</p>
        <div className="card__footer">
          <div className="card__links">
            {link ? <Link to={link} target="_blank" title={"Project"} rel="noreferrer">
              <StaticImage src={'../../images/link.svg'} alt={"Project"} className="icon" />
            </Link> : null}
            {githubLink ? <Link to={githubLink} target="_blank" title={"github"} rel="noreferrer">
              <StaticImage src={'../../images/github.svg'} alt={"github"} className="icon" />
            </Link> : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Card
