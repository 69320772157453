import React, { useEffect, useState, useRef } from 'react'
import { useClickOutside } from '../../hooks/user-click-outside'
import { useTheme } from '../../hooks/use-theme'
import Link from '../link'
import { isBrowser } from '../../util'

const Header = () => {
  const menuRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [_, toggleTheme] = useTheme()
  const mobileQuery = isBrowser && getComputedStyle(document.body).getPropertyValue(
    "--phoneWidth"
  );
  useEffect(() => {
    const checkIsMobile = () => {
      isBrowser && setIsMobile(window.matchMedia(mobileQuery).matches);
    }
    checkIsMobile()
    isBrowser && window.addEventListener('resize', checkIsMobile)
    return
  }, [])
  useClickOutside(menuRef, () => setIsMenuOpen(false));

  const menuTriggerClassList = isMobile ? 'menu-trigger' : 'menu-trigger hidden'
  const menuClassList = isMobile && !isMenuOpen ? 'menu hidden' : 'menu'
  return (
    <header className="header">
      <span className="header__inner">
        {/* <Link to="/" style={{ textDecoration: "none" }}>
          <div className="logo">
            <span className="logo__mark">{'>'}</span>
            <span className="logo__text">hello</span>
            <span className="logo__cursor">
            </span>
          </div>
        </Link> */}
        <div />

        <span className="header__right">
          <nav className={menuClassList} ref={menuRef}>
            <ul className="menu__inner">
              <li><Link to="/">Home</Link></li>
              <li><Link to="/about">About</Link></li>
              <li><Link to="/portfolio">Portfolio</Link></li>
              {/* <li><Link a="/blog">Blog</Link></li> */}
            </ul>
          </nav>
          <span className={menuTriggerClassList} onClick={() => setIsMenuOpen(true)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
            </svg>
          </span>
          <span className="theme-toggle not-selectable" onClick={toggleTheme}>
            <svg className="theme-toggler" width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M22 41C32.4934 41 41 32.4934 41 22C41 11.5066 32.4934 3 22
   3C11.5066 3 3 11.5066 3 22C3 32.4934 11.5066 41 22 41ZM7 22C7
   13.7157 13.7157 7 22 7V37C13.7157 37 7 30.2843 7 22Z"
              />
            </svg></span>
        </span>
      </span>
    </header>
  )
}

export default Header
